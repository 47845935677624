.Active-link_S {
	font-family: 'Libre Franklin';
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 20px;
	color: #5566fb;
	cursor: pointer;

	ion-icon {
		font-size: 16px;
		padding-left: 8px;
	}
}

.Active-link_M {
	font-family: 'Libre Franklin';
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 24px;
	color: #5566fb;
	cursor: pointer;

	ion-icon {
		font-size: 20px;
		padding-left: 8px;
	}
}

.Active-link_L {
	font-family: 'Libre Franklin';
	font-style: normal;
	font-weight: 400;
	font-size: 20px;
	line-height: 32px;
	color: #5566fb;
	cursor: pointer;

	ion-icon {
		font-size: 24px;
		padding-left: 8px;
	}
}
