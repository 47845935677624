@import './styles/tooltip.scss';
@import './styles/typography.scss';

ion-grid,
ion-col,
p {
    padding: 0;
    margin: 0;
}

.ripple-parent {
    position: relative;
    overflow: hidden;
}

ion-spinner {
    vertical-align: top;
}

ion-ripple-effect {
    --ripple-color: transparent;
}

img {
    object-fit: cover;
}

.cursor {
    cursor: pointer;
}