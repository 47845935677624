@mixin large-button {
	margin: 0;
	--border-radius: 8px;
	--padding-top: 12px;
	--padding-bottom: 12px;
	--padding-start: 12px;
	--padding-end: 12px;
	--box-shadow: none;
	text-transform: none;
	font-weight: 400;
	font-size: 16px;
	line-height: 24px;
	height: 48px;
	min-height: 48px;
	max-height: 48px;
}

@mixin large-button-icon {
	@include large-button;

	--padding-top: 12px;
	--padding-bottom: 12px;
	--padding-start: 48px;
	--padding-end: 48px;
}

@mixin primary-color {
	--background: #ffcb2d;
	color: #000000;
}

.Large-Button-Active_primary {
	@include large-button;
	@include primary-color;
}

.Large-Button-Active_primary_with_icon {
	@include large-button;
	@include primary-color;

	ion-icon {
		font-size: 24px;
		margin-left: 8px;
	}
}

.Large-Button-Active_primary_icon_only {
	@include large-button-icon;
	@include primary-color;

	ion-icon {
		font-size: 24px;
	}
}

@mixin secondary-color {
	--background: transparent;
	color: #000000;
	--border-radius: 8px;
	--border-width: 1px;
	--border-style: solid;
	--border-color: rgba(0, 0, 0, 0.8);
}

.Large-Button-Active_secondary {
	@include large-button;
	@include secondary-color;
}

.Large-Button-Active_secondary_with_icon {
	@include large-button;
	@include secondary-color;

	ion-icon {
		width: 20px;
		height: 20px;
		margin-left: 8px;
	}
}

.Large-Button-Active_secondary_icon_only {
	@include large-button-icon;
	@include secondary-color;

	--padding-start: 12px;
	--padding-end: 12px;

	ion-icon {
		font-size: 24px;
	}
}

@mixin secondary-white-color {
	--background: transparent;
	color: #ffffff;
	--border-radius: 8px;
	--border-width: 1px;
	--border-style: solid;
	--border-color: rgba(255, 255, 255, 0.8);
}

.Large-Button-Active_secondary_white {
	@include large-button;
	@include secondary-white-color;
}

.Large-Button-Active_secondary_white_with_icon {
	@include large-button;
	@include secondary-white-color;

	ion-icon {
		font-size: 24px;
		margin-left: 8px;
	}
}

.Large-Button-Active_secondary_white_icon_only {
	@include large-button-icon;
	@include secondary-white-color;

	ion-icon {
		font-size: 24px;
	}
}

@mixin inactive-primary-color {
	--background: linear-gradient(0deg,
			rgba(255, 255, 255, 0.65),
			rgba(255, 255, 255, 0.65)),
		#ffcb2d;
	color: rgba(0, 0, 0, 0.4);
}

.Large-Button-Inactive_primary {
	@include large-button;
	@include inactive-primary-color;
}

.Large-Button-Inactive_primary_with_icon {
	@include large-button;
	@include inactive-primary-color;

	ion-icon {
		font-size: 24px;
		margin-left: 8px;
	}
}

.Large-Button-Inactive_primary_icon_only {
	@include large-button-icon;
	@include inactive-primary-color;

	ion-icon {
		font-size: 24px;
	}
}

@mixin inInactive-secondary-color {
	--background: transparent;
	color: rgba(0, 0, 0, 0.4);
	--border-radius: 8px;
	--border-width: 1px;
	--border-style: solid;
	--border-color: rgba(0, 0, 0, 0.2);
}

.Large-Button-Inactive_secondary {
	@include large-button;
	@include secondary-color;
}

.Large-Button-Inactive_secondary_with_icon {
	@include large-button;
	@include secondary-color;

	ion-icon {
		font-size: 24px;
		margin-left: 8px;
	}
}

.Large-Button-Inactive_secondary_icon_only {
	@include large-button-icon;
	@include secondary-color;

	ion-icon {
		font-size: 24px;
	}
}