.ng-tooltip {
	position: absolute;
	font-family: 'Libre Franklin';
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 20px;
	color: #ffffff;
	padding: 16px;
	background: #000000;
	border-radius: 8px;
	box-shadow: 0px 1px 12px rgba(0, 0, 0, 0.1);
	z-index: 1000;
	opacity: 0;
	max-width: 100vw;
}
.ng-tooltip:after {
	content: '';
	position: absolute;
	border-style: solid;
}
.ng-tooltip-top:after {
	top: 100%;
	left: 45%;
	margin-top: 0;
	border-width: 8px;
	border-color: black transparent transparent transparent;
}
.ng-tooltip-bottom:after {
	bottom: 100%;
	left: 45%;
	margin-top: -8px;
	border-width: 8px;
	border-color: transparent transparent black transparent;
}
.ng-tooltip-left:after {
	top: 50%;
	left: 100%;
	margin-top: -8px;
	border-width: 8px;
	border-color: transparent transparent transparent black;
}
.ng-tooltip-right:after {
	top: 50%;
	right: 100%;
	margin-top: -8px;
	border-width: 8px;
	border-color: transparent black transparent transparent;
}
.ng-tooltip-show {
	opacity: 1;
}
