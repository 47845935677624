.Elevation-1_Flat {
	background: #ffffff;
	box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.08);
	border-radius: 8px;
}

.Elevation-1_Low {
	background: #ffffff;
	box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.1);
	border-radius: 8px;
}

.Elevation-2_Medium {
	background: #ffffff;
	box-shadow: 0px 1px 12px rgba(0, 0, 0, 0.1);
	border-radius: 8px;
}

.Elevation-3_High {
	background: #ffffff;
	box-shadow: 0px 1px 16px rgba(0, 0, 0, 0.1);
	border-radius: 8px;
}

.hover_Elevation-1_Flat {
	&:hover {
		box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.08);
	}
}

.hover_Elevation-1_Low {
	&:hover {
		box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.1);
	}
}

.hover_Elevation-2_Medium {
	&:hover {
		box-shadow: 0px 1px 12px rgba(0, 0, 0, 0.1);
	}
}

.hover_Elevation-3_High {
	&:hover {
		box-shadow: 0px 1px 16px rgba(0, 0, 0, 0.1);
	}
}
