.visible {
  visibility: visible
}

.static {
  position: static
}

.fixed {
  position: fixed
}

.absolute {
  position: absolute
}

.relative {
  position: relative
}

.float-left {
  float: left
}

.mx-16 {
  margin-left: 16px;
  margin-right: 16px
}

.mx-20 {
  margin-left: 20px;
  margin-right: 20px
}

.my-16 {
  margin-top: 16px;
  margin-bottom: 16px
}

.my-24 {
  margin-top: 24px;
  margin-bottom: 24px
}

.my-28 {
  margin-top: 28px;
  margin-bottom: 28px
}

.mb-12 {
  margin-bottom: 12px
}

.mb-16 {
  margin-bottom: 16px
}

.mb-32 {
  margin-bottom: 32px
}

.mb-8 {
  margin-bottom: 8px
}

.ml-12 {
  margin-left: 12px
}

.ml-8 {
  margin-left: 8px
}

.mr-16 {
  margin-right: 16px
}

.mt-16 {
  margin-top: 16px
}

.mt-2 {
  margin-top: 2px
}

.mt-20 {
  margin-top: 20px
}

.mt-24 {
  margin-top: 24px
}

.mt-32 {
  margin-top: 32px
}

.mt-8 {
  margin-top: 8px
}

.block {
  display: block
}

.flex {
  display: flex
}

.grid {
  display: grid
}

.hidden {
  display: none
}

.h-50 {
  height: 50px
}

.w-full {
  width: 100%
}

.transform {
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}

.cursor-pointer {
  cursor: pointer
}

.flex-wrap {
  flex-wrap: wrap
}

.items-center {
  align-items: center
}

.justify-start {
  justify-content: flex-start
}

.justify-end {
  justify-content: flex-end
}

.justify-center {
  justify-content: center
}

.overflow-y-scroll {
  overflow-y: scroll
}

.rounded-full {
  border-radius: 9999px
}

.rounded-lg {
  border-radius: 0.5rem
}

.rounded-md {
  border-radius: 0.375rem
}

.border {
  border-width: 1px
}

.bg-transparent {
  background-color: transparent
}

.bg-white {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity))
}

.p-16 {
  padding: 16px
}

.p-20 {
  padding: 20px
}

.p-32 {
  padding: 32px
}

.px-16 {
  padding-left: 16px;
  padding-right: 16px
}

.py-16 {
  padding-top: 16px;
  padding-bottom: 16px
}

.pb-16 {
  padding-bottom: 16px
}

.pb-20 {
  padding-bottom: 20px
}

.pb-24 {
  padding-bottom: 24px
}

.pb-8 {
  padding-bottom: 8px
}

.pt-24 {
  padding-top: 24px
}

.pt-8 {
  padding-top: 8px
}

.text-center {
  text-align: center
}

.text-right {
  text-align: right
}

.align-middle {
  vertical-align: middle
}

.font-sans {
  font-family: Libre Franklin, sans-serif
}

.text-2xl {
  font-size: 1.5rem;
  line-height: 2rem
}

.text-3xl {
  font-size: 1.875rem;
  line-height: 2.25rem
}

.text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem
}

.text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem
}

.font-bold {
  font-weight: 700
}

.font-normal {
  font-weight: 400
}

.uppercase {
  text-transform: uppercase
}

.lowercase {
  text-transform: lowercase
}

.capitalize {
  text-transform: capitalize
}

.normal-case {
  text-transform: none
}

.leading-6 {
  line-height: 1.5rem
}

.text-black {
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity))
}

.text-blue-600 {
  --tw-text-opacity: 1;
  color: rgb(37 99 235 / var(--tw-text-opacity))
}

.text-rose-600 {
  --tw-text-opacity: 1;
  color: rgb(225 29 72 / var(--tw-text-opacity))
}

.text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity))
}

.no-underline {
  text-decoration-line: none
}

.shadow-md {
  --tw-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.filter {
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow)
}
