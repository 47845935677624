.background-Blues-Dark_Blue_3 {
	background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
		#5566fb;
}

.background-Blues-Dark_Blue_2 {
	background: linear-gradient(0deg, rgba(0, 0, 0, 0.37), rgba(0, 0, 0, 0.37)),
		#5566fb;
}

.background-Blues-Dark_Blue_1 {
	background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
		#5566fb;
}

.background-Blues-Default_Blue {
	background: #5566fb;
}

.background-Blues-Light_Blue_1 {
	background: linear-gradient(
			0deg,
			rgba(255, 255, 255, 0.5),
			rgba(255, 255, 255, 0.5)
		),
		#5566fb;
}

.background-Blues-Light_Blue_2 {
	background: linear-gradient(
			0deg,
			rgba(255, 255, 255, 0.8),
			rgba(255, 255, 255, 0.8)
		),
		#5566fb;
}

.background-Blues-Light_Blue_3 {
	background: linear-gradient(
			0deg,
			rgba(255, 255, 255, 0.94),
			rgba(255, 255, 255, 0.94)
		),
		#5566fb;
}

.color-Blues-Dark_Blue_3 {
	color: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), #5566fb;
}

.color-Blues-Dark_Blue_2 {
	color: linear-gradient(0deg, rgba(0, 0, 0, 0.37), rgba(0, 0, 0, 0.37)),
		#5566fb;
}

.color-Blues-Dark_Blue_1 {
	color: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), #5566fb;
}

.color-Blues-Default_Blue {
	color: #5566fb;
}

.color-Blues-Light_Blue_1 {
	color: linear-gradient(
			0deg,
			rgba(255, 255, 255, 0.5),
			rgba(255, 255, 255, 0.5)
		),
		#5566fb;
}

.color-Blues-Light_Blue_2 {
	color: linear-gradient(
			0deg,
			rgba(255, 255, 255, 0.8),
			rgba(255, 255, 255, 0.8)
		),
		#5566fb;
}

.color-Blues-Light_Blue_3 {
	color: linear-gradient(
			0deg,
			rgba(255, 255, 255, 0.94),
			rgba(255, 255, 255, 0.94)
		),
		#5566fb;
}
