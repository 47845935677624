/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import '~@ionic/angular/css/core.css';

/* Basic CSS for apps built with Ionic */

@import '~@ionic/angular/css/normalize.css';
@import '~@ionic/angular/css/structure.css';

@import './theme/output.css';

@import '~@ng-select/ng-select/themes/default.theme.css';

@import '~@mobiscroll/angular/dist/css/mobiscroll.angular.min.css';

@import '~quill/dist/quill.core.css';
@import '~quill/dist/quill.bubble.css';
@import '~quill/dist/quill.snow.css';

.logout-modal-class {
	--width: 254px;
	--box-shadow: 0 1px 16px rgba(0, 0, 0, 0.1);
}

.dx-datagrid .dx-column-lines > td {
	border-bottom: 1px solid #ddd;
}

app-manage-email,
app-epp-signups {
	overflow: scroll;
}

.confirm-box {
	--max-height: 197px;
	--min-height: 197px;
	--max-width: 652px;
	--min-width: 652px;

	background: rgb(0 0 0 / 77%);

	--border-radius: 8px;
}
