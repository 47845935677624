.background-Reds-Dark_Red_3 {
	background: linear-gradient(0deg, rgba(0, 0, 0, 0.65), rgba(0, 0, 0, 0.65)),
		#dd0000;
}

.background-Reds-Dark_Red_2 {
	background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
		#dd0000;
}

.background-Reds-Dark_Red_1 {
	background: linear-gradient(0deg, rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.25)),
		#dd0000;
}

.background-Reds-Default_Green {
	background: #dd0000;
}

.background-Reds-Light_Red_1 {
	background: linear-gradient(
			0deg,
			rgba(255, 255, 255, 0.7),
			rgba(255, 255, 255, 0.7)
		),
		#dd0000;
}

.background-Reds-Light_Red_2 {
	background: linear-gradient(
			0deg,
			rgba(255, 255, 255, 0.85),
			rgba(255, 255, 255, 0.85)
		),
		#dd0000;
}

.background-Reds-Light_Red_3 {
	background: linear-gradient(
			0deg,
			rgba(255, 255, 255, 0.96),
			rgba(255, 255, 255, 0.96)
		),
		#dd0000;
}

.color-Reds-Dark_Red_3 {
	color: linear-gradient(0deg, rgba(0, 0, 0, 0.65), rgba(0, 0, 0, 0.65)),
		#dd0000;
}

.color-Reds-Dark_Red_2 {
	color: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), #dd0000;
}

.color-Reds-Dark_Red_1 {
	color: linear-gradient(0deg, rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.25)),
		#dd0000;
}

.color-Reds-Default_Green {
	color: #dd0000;
}

.color-Reds-Light_Red_1 {
	color: linear-gradient(
			0deg,
			rgba(255, 255, 255, 0.7),
			rgba(255, 255, 255, 0.7)
		),
		#dd0000;
}

.color-Reds-Light_Red_2 {
	color: linear-gradient(
			0deg,
			rgba(255, 255, 255, 0.85),
			rgba(255, 255, 255, 0.85)
		),
		#dd0000;
}

.color-Reds-Light_Red_1 {
	color: linear-gradient(
			0deg,
			rgba(255, 255, 255, 0.96),
			rgba(255, 255, 255, 0.96)
		),
		#dd0000;
}
