.background-Yellows-Dark_Yellow_3 {
	background: linear-gradient(0deg, rgba(0, 0, 0, 0.32), rgba(0, 0, 0, 0.32)),
		#ffcb2d;
}

.background-Yellows-Dark_Yellow_2 {
	background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
		#ffcb2d;
}

.background-Yellows-Dark_Yellow_1 {
	background: linear-gradient(0deg, rgba(0, 0, 0, 0.08), rgba(0, 0, 0, 0.08)),
		#ffcb2d;
}

.background-Yellows-Default_Green {
	background: #ffcb2d;
}

.background-Yellows-Light_Yellow_1 {
	background: linear-gradient(
			0deg,
			rgba(255, 255, 255, 0.35),
			rgba(255, 255, 255, 0.35)
		),
		#ffcb2d;
}

.background-Yellows-Light_Yellow_2 {
	background: linear-gradient(
			0deg,
			rgba(255, 255, 255, 0.65),
			rgba(255, 255, 255, 0.65)
		),
		#ffcb2d;
}

.background-Yellows-Light_Yellow_3 {
	background: linear-gradient(
			0deg,
			rgba(255, 255, 255, 0.9),
			rgba(255, 255, 255, 0.9)
		),
		#ffcb2d;
}

.color-Yellows-Dark_Yellow_3 {
	color: linear-gradient(0deg, rgba(0, 0, 0, 0.32), rgba(0, 0, 0, 0.32)),
		#ffcb2d;
}

.color-Yellows-Dark_Yellow_2 {
	color: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), #ffcb2d;
}

.color-Yellows-Dark_Yellow_1 {
	color: linear-gradient(0deg, rgba(0, 0, 0, 0.08), rgba(0, 0, 0, 0.08)),
		#ffcb2d;
}

.color-Yellows-Default_Green {
	color: #ffcb2d;
}

.color-Yellows-Light_Yellow_1 {
	color: linear-gradient(
			0deg,
			rgba(255, 255, 255, 0.35),
			rgba(255, 255, 255, 0.35)
		),
		#ffcb2d;
}

.color-Yellows-Light_Yellow_2 {
	color: linear-gradient(
			0deg,
			rgba(255, 255, 255, 0.65),
			rgba(255, 255, 255, 0.65)
		),
		#ffcb2d;
}

.color-Yellows-Light_Yellow_3 {
	color: linear-gradient(
			0deg,
			rgba(255, 255, 255, 0.9),
			rgba(255, 255, 255, 0.9)
		),
		#ffcb2d;
}
