.background-Blacks-Default_Black {
	background: #000000;
}

.background-Blacks-Default_Black_1 {
	background: rgba(0, 0, 0, 0.8);
}

.background-Blacks-Default_Black_2 {
	background: rgba(0, 0, 0, 0.6);
}

.background-Blacks-Default_Black_3 {
	background: rgba(0, 0, 0, 0.4);
}

.background-Blacks-Default_Black_4 {
	background: rgba(0, 0, 0, 0.2);
}

.background-Blacks-Default_Black_5 {
	background: rgba(0, 0, 0, 0.08);
}

.background-Blacks-Default_Black_6 {
	background: linear-gradient(0deg, rgba(0, 0, 0, 0.01), rgba(0, 0, 0, 0.01)),
		#ffffff;
}

.color-Blacks-Default_Black {
	color: #000000;
}

.color-Blacks-Default_Black_1 {
	color: rgba(0, 0, 0, 0.8);
}

.color-Blacks-Default_Black_2 {
	color: rgba(0, 0, 0, 0.6);
}

.color-Blacks-Default_Black_3 {
	color: rgba(0, 0, 0, 0.4);
}

.color-Blacks-Default_Black_4 {
	color: rgba(0, 0, 0, 0.2);
}

.color-Blacks-Default_Black_5 {
	color: rgba(0, 0, 0, 0.08);
}

.color-Blacks-Default_Black_6 {
	color: linear-gradient(0deg, rgba(0, 0, 0, 0.01), rgba(0, 0, 0, 0.01)),
		#ffffff;
}
