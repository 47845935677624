.background-Greens-Dark_Green_3 {
	background: linear-gradient(0deg, rgba(0, 0, 0, 0.45), rgba(0, 0, 0, 0.45)),
		#00daae;
}

.background-Greens-Dark_Green_2 {
	background: linear-gradient(0deg, rgba(0, 0, 0, 0.32), rgba(0, 0, 0, 0.32)),
		#00daae;
}

.background-Greens-Dark_Green_1 {
	background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
		#00daae;
}

.background-Greens-Default_Green {
	background: #00daae;
}

.background-Greens-Light_Green_1 {
	background: linear-gradient(
			0deg,
			rgba(255, 255, 255, 0.5),
			rgba(255, 255, 255, 0.5)
		),
		#00daae;
}

.background-Greens-Light_Green_2 {
	background: linear-gradient(
			0deg,
			rgba(255, 255, 255, 0.77),
			rgba(255, 255, 255, 0.77)
		),
		#00daae;
}

.background-Greens-Light_Green_3 {
	background: linear-gradient(
			0deg,
			rgba(255, 255, 255, 0.94),
			rgba(255, 255, 255, 0.94)
		),
		#00daae;
}

.color-Greens-Dark_Green_3 {
	color: linear-gradient(0deg, rgba(0, 0, 0, 0.45), rgba(0, 0, 0, 0.45)),
		#00daae;
}

.color-Greens-Dark_Green_2 {
	color: #009476;
}

.color-Greens-Dark_Green_1 {
	color: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), #00daae;
}

.color-Greens-Default_Green {
	color: #00daae;
}

.color-Greens-Light_Green_1 {
	color: linear-gradient(
			0deg,
			rgba(255, 255, 255, 0.5),
			rgba(255, 255, 255, 0.5)
		),
		#00daae;
}

.color-Greens-Light_Green_2 {
	color: linear-gradient(
			0deg,
			rgba(255, 255, 255, 0.77),
			rgba(255, 255, 255, 0.77)
		),
		#00daae;
}

.color-Greens-Light_Green_3 {
	color: linear-gradient(
			0deg,
			rgba(255, 255, 255, 0.94),
			rgba(255, 255, 255, 0.94)
		),
		#00daae;
}
