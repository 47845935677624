@mixin small-button {
	margin: 0;
	--border-radius: 8px;
	--padding-top: 8px;
	--padding-bottom: 8px;
	--padding-start: 8px;
	--padding-end: 8px;
	--box-shadow: none;
	text-transform: none;
	font-weight: 400;
	font-size: 14px;
	line-height: 20px;
	height: 40px;
	min-height: 40px;
	max-height: 40px;
}

@mixin small-button-icon {
	@include small-button;

	--padding-top: 8px;
	--padding-bottom: 8px;
	--padding-start: 32px;
	--padding-end: 32px;
}

@mixin primary-color {
	--background: #ffcb2d;
	color: #000000;
}

.Small-button-Active_primary {
	@include small-button;
	@include primary-color;
}

.Small-button-Active_primary_with_icon {
	@include small-button;
	@include primary-color;

	ion-icon {
		font-size: 24px;
		margin-left: 8px;
	}
}

.Small-button-Active_primary_icon_only {
	@include small-button-icon;
	@include primary-color;

	--padding-start: 12px;
	--padding-end: 12px;

	ion-icon {
		font-size: 24px;
	}
}

@mixin secondary-color {
	--background: transparent;
	color: #000000;
	--border-radius: 8px;
	--border-width: 1px;
	--border-style: solid;
	--border-color: rgba(0, 0, 0, 0.8);
}

.Small-button-Active_secondary {
	@include small-button;
	@include secondary-color;
}

.Small-button-Active_secondary_with_icon {
	@include small-button;
	@include secondary-color;

	ion-icon {
		font-size: 24px;
		margin-left: 8px;
	}
}

.Small-button-Active_secondary_icon_only {
	@include small-button-icon;
	@include secondary-color;

	ion-icon {
		font-size: 24px;
	}
}

@mixin secondary-white-color {
	--background: transparent;
	color: #ffffff;
	--border-radius: 8px;
	--border-width: 1px;
	--border-style: solid;
	--border-color: rgba(255, 255, 255, 0.8);
}

.Small-button-Active_secondary_white {
	@include small-button;
	@include secondary-white-color;
}

.Small-button-Active_secondary_white_with_icon {
	@include small-button;
	@include secondary-white-color;

	ion-icon {
		font-size: 24px;
		margin-left: 8px;
	}
}

.Small-button-Active_secondary_white_icon_only {
	@include small-button-icon;
	@include secondary-white-color;

	ion-icon {
		font-size: 24px;
	}
}


@media screen and (max-width:992px) {
	.M_Small-button-Active_primary{
		@include small-button;
		@include primary-color;
	
		ion-icon {
			font-size: 24px;
			margin-left: 8px;
		}
	}
}

