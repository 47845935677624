.background-Whites-Default_White {
	background: #ffffff;
}

.background-Whites-Default_White_1 {
	background: rgba(255, 255, 255, 0.8);
}

.background-Whites-Default_White_2 {
	background: rgba(255, 255, 255, 0.7);
}

.background-Whites-Default_White_3 {
	background: rgba(255, 255, 255, 0.6);
}

.background-Whites-Default_White_4 {
	background: rgba(255, 255, 255, 0.5);
}

.background-Whites-Default_White_5 {
	background: rgba(255, 255, 255, 0.4);
}

.background-Whites-Default_White_6 {
	background: rgba(255, 255, 255, 0.2);
}

.color-Whites-Default_White {
	color: #ffffff;
}

.color-Whites-Default_White_1 {
	color: rgba(255, 255, 255, 0.8);
}

.color-Whites-Default_White_2 {
	color: rgba(255, 255, 255, 0.7);
}

.color-Whites-Default_White_3 {
	color: rgba(255, 255, 255, 0.6);
}

.color-Whites-Default_White_4 {
	color: rgba(255, 255, 255, 0.5);
}

.color-Whites-Default_White_5 {
	color: rgba(255, 255, 255, 0.4);
}

.color-Whites-Default_White_6 {
	color: rgba(255, 255, 255, 0.2);
}
